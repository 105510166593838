/* General  */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 1.2rem;
  line-height: 1.2;
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

ul {
  list-style: none;
}

H3,
H4,
H5,
H6 {
  margin: 2rem 0;
}

p {
  margin-bottom: 0.5rem;
  line-height: 1.5em;
}

.paragraph {
  text-indent: 15px;
}

.container {
  max-width: 750px;
  margin: 0 0 80px 160px;

  padding: 0 25px 25px;
}

.portrait {
  display: block;
  object-fit: cover;
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

.portrait-media {
  display: none;
}

/* Navbar ------------ */

nav {
  display: block;
  position: fixed;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 1.5rem;
}

.navbar ul {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  margin-top: 15px;
  font-weight: 600;
}

.navbar li a {
  display: block;
  padding: 2px 20px 2px 5px;
}

.navbar li:hover {
  background: #e9e9e9;
  border-radius: 5px;
}

/* Header ------------ */

.header {
  padding: 20px 0;
}

.header h1 {
  font-size: 3.5em;
}

.header h2 {
  color: #999;
}

h2 {
  color: #999;
  margin-bottom: 1rem;
}

.name {
  padding-left: 15px;
  font-family: 'Quicksand', sans-serif;
}

/* Form ------------ */

.form-wrap {
  padding-right: 150px;
}

.text-fields {
  display: flex;
  flex-direction: column;
}

.text-input,
.contact-message {
  padding: 0.5rem 1rem;
  font-family: 'Lato', sans-serif;
  margin-bottom: 1rem;
  border: #777 1px solid;
  border-radius: 5px;
  width: 100%;
}

.contact-message {
  height: 200px;
}

/* Button ------------ */
.btn-dark {
  display: block;
  padding: 0.8rem 2rem;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  background: #333;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  margin-top: 5px;
  margin-left: auto;
}

/* Skills Section ------------ */

.skills-section {
  margin: 30px 0 40px;
}

.skills-section h4,
h5 {
  margin: 0;
}

.skill-level {
  overflow: hidden;
  height: 20px;
  background-color: #ccc;
  border-radius: 5px;
  margin-bottom: 0.6rem;
}

.skill-level div {
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: #ffbc00;
}

/* Projects ------------------------ */

.projects {
  display: grid;
  grid-template-columns: 1fr 3fr;
  row-gap: 20px;
  margin: 20px auto;
}

.projects p {
  margin: 0;
  padding: 0;
}

.project-link {
  font-size: 1.5rem;
  font-weight: 700;
  display: block;
  background: #8bcbf5;
  border-radius: 5px;
  padding: 2px 20px 2px 5px;
  margin-right: 25px;
}

.link {
  padding: 2px 3px;
  border-bottom: 1px dotted #333;
}

.code-link {
  background: #eaf58b;
  padding: 2px 3px;
  border-radius: 5px;
}

/*JobTME ----------------- */

.jobTME-credentials {
  background-color: aliceblue;
  margin: 10px 0;
}

.jobTME h3 {
  color: #c7cf78;
  margin-bottom: 15px;
}

.jobTME h4 {
  margin-bottom: 15px;
}

.jobTME ul {
  margin-left: 20px;
  list-style: unset;
}

.jobTME-app-list li {
  margin: 0;
}

.jobTME-functionality-list li,
.jobTME-validation-list li {
  margin: 15px 0;
}

.jobTME-img {
  margin-bottom: 20px;
}

.jobTME-img.mobile {
  margin-left: 15px;
}

/* Media - Tablets ----------------- */

@media (max-width: 768px) {
  .header {
    text-align: center;
    padding-top: 0;
  }

  nav {
    display: flex;
    position: static;
    background: #fff;
    padding: 10px 0;
    justify-content: space-evenly;
  }

  .navbar ul {
    flex-direction: row;
    padding-left: 0;
    margin: 5px;
  }

  .container {
    max-width: 750px;
    margin: 0 auto;
    padding: 0 25px 25px;
  }

  .portrait {
    display: none;
  }
  .portrait-media {
    display: block;
    object-fit: cover;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin: auto;
  }

  .name {
    padding-top: 6px;
  }

  .form-wrap {
    padding: 0;
    margin: 0 50px;
  }

  .text-fields {
    align-items: center;
  }
}

/* Media - Phones ----------------- */

@media (max-width: 600px) {
  nav {
    display: flex;
    flex-direction: column;
    position: static;
    background: #fff;
    margin-top: 10px;
    margin-left: 0;
    padding: 5px 0;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }

  p {
    text-align: center;
  }

  .navbar li a {
    font-size: 1.2rem;
    padding: 2px 10px;
  }

  .name {
    padding: 5px;
  }

  .form-wrap {
    padding: 0;
    margin: 0;
  }

  .projects {
    grid-template-columns: 1fr;
    row-gap: 10px;
    margin: 20px auto;
  }

  .project-link {
    text-align: center;
    padding: 2px 5px;
    margin-right: 0;
  }

  .projects p {
    margin-bottom: 20px;
  }
}
